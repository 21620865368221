<template>
  <el-main>
    <ics-dialog-inner :loading="loading.submit" :visible.sync="uploadDialog.visible" :title="uploadDialog.title" submit-title="上传" cancel-title="关闭" class="form-dialog" width="50%" @submit="submit" @close="close">
      <ics-page-inner title="发票信息" :show-header="true">
        <el-form ref="uploadForm" :model="formInfo" :rules="rules" label-width="140px" label-position="left" :label-suffix="constants.labelSuffix">
          <el-row :gutter="80">
            <el-col :span="12">
              <el-form-item label="发票类型">
                <el-select v-model="formInfo.invoiceKind" placeholder="请选择发票类型" filterable>
                  <el-option v-for="item in constants.invoiceKindType" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="upload-inner" style="width: 90%; top: 50%; left: 50%; margin-left: 40px;">
            <fero-upload
              ref="upload"
              v-model="formInfo.fileList"
              :limit="limit"
              :action="uploadUrl"
              :headers="headers"
              drag
              accept=".jpg,.png"
              :auto-upload="false"
              :before-upload="beforeUploadFile"
              :on-success="onSuccessFile"
              :on-preview="previewFile"
              :on-error="onErrorFile"
              :on-change="onChange"
              :on-remove="onChange"
              :on-exceed="onExceed"
            >
              <i class="el-icon-upload" />
              <div class="el-upload__text">
                将文件拖到此处，或<em>点击上传</em>
              </div>
              <div class="el-upload__text">
                上传文件只能是 jpg、png格式
              </div>
            </fero-upload>
          </div>
        </el-form>
      </ics-page-inner>
    </ics-dialog-inner>
    <ics-dialog-inner :visible.sync="confirmDialog.visible" :title="confirmDialog.title" :show-submit="false" :show-cancel="false" class="form-dialog" width="60%">
      <div class="form-area" style="width: 100%;height: 380px;overflow:auto;overflow-x:hidden;">
        <div class="form-inner">
          <el-form :model="invoiceDetail" label-width="120px">
            <el-row :gutter="80">
              <el-col :span="12">
                <el-form-item label="发票类型：">
                  <p>{{ utils.statusFormat(invoiceDetail.invoiceType, 'invoiceType') }}</p>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="发票号码：">
                  <p>{{ utils.isEffectiveCommon(invoiceDetail.invoiceNumber) }}</p>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="发票日期：">
                  <p>{{ utils.isEffectiveCommon(invoiceDetail.billingDate) }}</p>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="发票代码：">
                  <p>{{ utils.isEffectiveCommon(invoiceDetail.invoiceCode) }}</p>
                </el-form-item>
              </el-col>
            </el-row>
            <div class="form-area">
              <el-row :gutter="80">
                <el-col :span="12">
                  <div class="area-title">
                    <p class="title">
                      购买方
                    </p>
                  </div>
                  <div class="form-inner">
                    <el-row :gutter="80">
                      <el-col :span="24">
                        <el-form-item label="名称：">
                          <p>{{ utils.isEffectiveCommon(invoiceDetail.buyerTaxName) }}</p>
                        </el-form-item>
                      </el-col>
                      <el-col :span="24">
                        <el-form-item label="纳税人识别号：">
                          <p>{{ utils.isEffectiveCommon(invoiceDetail.buyerTaxNumber) }}</p>
                        </el-form-item>
                      </el-col>
                      <el-col :span="24">
                        <el-form-item label="地址、电话：">
                          <p>{{ utils.isEffectiveCommon(invoiceDetail.buyerAddressPhone) }}</p>
                        </el-form-item>
                      </el-col>
                      <el-col :span="24">
                        <el-form-item label="开户行及账号：">
                          <p>{{ utils.isEffectiveCommon(invoiceDetail.buyerBankAccount) }}</p>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="area-title">
                    <p class="title">
                      销售方
                    </p>
                  </div>
                  <div class="form-inner">
                    <el-row :gutter="80">
                      <el-col :span="24">
                        <el-form-item label="名称：">
                          <p>{{ utils.isEffectiveCommon(invoiceDetail.sellerTaxName) }}</p>
                        </el-form-item>
                      </el-col>
                      <el-col :span="24">
                        <el-form-item label="纳税人识别号：">
                          <p>{{ utils.isEffectiveCommon(invoiceDetail.sellerTaxNumber) }}</p>
                        </el-form-item>
                      </el-col>
                      <el-col :span="24">
                        <el-form-item label="地址、电话：">
                          <p>{{ utils.isEffectiveCommon(invoiceDetail.sellerAddressPhone) }}</p>
                        </el-form-item>
                      </el-col>
                      <el-col :span="24">
                        <el-form-item label="开户行及账号：">
                          <p>{{ utils.isEffectiveCommon(invoiceDetail.sellerBankAccount) }}</p>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </div>
                </el-col>
              </el-row>
            </div>
            <div v-for="(item, index) in invoiceDetail.invoiceDetails" :key="index" class="form-area">
              <div class="area-title">
                <p class="title">
                  货物或应税劳务、服务名称
                </p>
              </div>
              <div class="form-inner">
                <el-row :gutter="80">
                  <el-col :span="12">
                    <el-form-item label="规格型号：">
                      <p>{{ utils.isEffectiveCommon(item.model) }}</p>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="单位：">
                      <p>{{ utils.isEffectiveCommon(item.units) }}</p>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="数量：">
                      <p>{{ utils.isEffectiveCommon(item.quantity) }}</p>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="单价：">
                      <p>{{ utils.isEffectiveCommon(item.unitPrice) }}</p>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="税率：">
                      <p>{{ utils.isEffectiveCommon(item.taxRate) }}</p>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="税额：">
                      <p>{{ utils.isEffectiveCommon(item.tax) }}</p>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
            </div>
            <div class="form-area">
              <div class="form-inner">
                <el-row :gutter="80">
                  <el-col :span="12">
                    <el-form-item label="价税合计(大写）：">
                      <p>{{ utils.isEffectiveCommon(invoiceDetail.totalAmountStr) }}</p>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="价税合计(小写）：">
                      <p>{{ utils.isEffectiveCommon(invoiceDetail.totalAmount) }}</p>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="合计金额：">
                      <p>{{ utils.isEffectiveCommon(invoiceDetail.totalAmountTax) }}</p>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="合计税额：">
                      <p>{{ utils.isEffectiveCommon(invoiceDetail.totalTax) }}</p>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
            </div>
            <div class="form-area">
              <div class="form-inner">
                <el-row :gutter="80">
                  <el-col :span="12">
                    <el-form-item label="收款人：">
                      <p>{{ utils.isEffectiveCommon(invoiceDetail.receiptName) }}</p>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="复核：">
                      <p>{{ utils.isEffectiveCommon(invoiceDetail.reviewerName) }}</p>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="开票人：">
                      <p>{{ utils.isEffectiveCommon(invoiceDetail.operatorName) }}</p>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
            </div>
          </el-form>
        </div>
      </div>
      <template slot="btn-behind">
        <el-col :span="24" style="padding: 0px 0px 20px;margin:0 auto; text-align: center; margin-top: 20px;">
          <el-button class="add-btn" @click="confirmClose">
            信息有误，重新识别
          </el-button>
          <el-button class="add-btn" type="primary" @click="confirmSubmit">
            确认无误
          </el-button>
        </el-col>
      </template>
    </ics-dialog-inner>
  </el-main>
</template>

<script>
import IcsDialogInner from '@/components/dialog-inner'
import { routeEnterMixin } from '@/assets/js/mixins'
import store from "../../../../store";
import utils from "../../../../assets/js/utils";
import cfca from "../../../../assets/js/cfca-prove";
class FormProp {
  fileList = []
}
export default {
  name: 'IcsUploadInner',
  components: { IcsDialogInner },
  mixins: [routeEnterMixin],
  props: {
    uploadDialog: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data () {
    return {
      baseUploadUrl: process.env.VUE_APP_REQUEST_BASE_URL + '/tenant/b/p/firm/invoice/invoiceAuth',
      uploadUrl: '',
      limit: 1,
      formInfo: {
        invoiceKind: '',
        fileList: []
      },
      headers: {
        Token: this.$store.state.token
      },
      loading: {
        submit: false
      },
      invoiceDetail: {},
      confirmDialog: {
        title: '',
        visible: false
      },
      rules: {}
    }
  },
  methods: {
    beforeUploadFile (file) {
      const fileSuffix = file.name.substring(file.name.lastIndexOf('.') + 1)
      const whiteList = ['jpg', 'png', 'jpeg']
      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message({
          showClose: true,
          message: '上传文件只能是 jpg、png、jpeg格式',
          type: 'warning'
        })
        this.loading.submit = false
        return false
      }
      const isLt = file.size / 1024 / 1024 < 2
      if (!isLt) {
        this.$message.error('上传文件最大为2M')
        this.loading.submit = false
        return isLt
      }
    },
    onSuccessFile (res, file) {
      if (res.success) {
        this.confirmDialog.title = '发票确认'
        this.confirmDialog.visible = true
        this.invoiceDetail = res.data
      } else {
        this.$message.error(res.message || '上传失败')
        this.formInfo.fileList = []
      }
      this.loading.submit = false
    },
    onErrorFile (err, file, fileList) {
      const msg = JSON.parse(err.message)
      if (msg.message.indexOf('code') >= 0) {
        const data = JSON.parse(msg.message)
        console.log(data)
        if (data.code === 60000) {
          if (utils.getExplorer() === 'ie') {
            cfca.dataHunter(data.employeeCode, msg.path)
          } else {
            this.$message.warning('当前浏览器为：'+ utils.getExplorer() + ',请使用IE浏览器进行数字证书身份认证')
          }
        }
      } else {
        this.$message.error(msg.message || '上传失败')
      }
      this.formInfo.fileList = []
      this.loading.submit = false
      if (err.status === 401) {
        this.$store.commit('removeEmpInfo')
        window.location.href = process.env.VUE_APP_CONSOLE_DOMAIN + '/login?redirecturl=' + encodeURIComponent(window.location.href)
      }
    },
    onChange (file, fileList) {
      this.formInfo.fileList = fileList
    },
    onExceed () {
      this.$message.error(`一次只能上传${this.limit}个文件`)
    },
    submit () {
      if (this._.isEmpty(this.formInfo.fileList)) {
        this.$message.warning('请上传附件')
        return false
      }
      if (!this.formInfo.invoiceKind) {
        this.$message.warning('请选择发票类型')
        return false
      }
      this.$refs.uploadForm.validate().then(() => {
        this.uploadUrl = this.baseUploadUrl
        this.$nextTick(() => {
          this.loading.submit = true
          this.$refs.upload.submit()
        })
      })
    },
    close () {
      this.$refs.upload.clearFiles()
      this.formInfo.invoiceKind = ''
      this.loading.submit = false
      this.$emit('close')
    },
    confirmClose () {
      this.confirmDialog.visible = false
      this.$refs.upload.clearFiles()
    },
    previewFile (file) {
      return false
    },
    confirmSubmit () {
      this.invoiceDetail.invoiceKind = this.formInfo.invoiceKind
      this.api.assets.invoice.confirm(this.invoiceDetail).then(result => {
        if (result.data.success === true) {
          this.loading.submit = false
          this.$message.success('保存成功')
          this.confirmDialog.visible = false
          this.$emit('confirmClose')
          this.$refs.upload.clearFiles()
          this.invoiceDetail = {}
        } else {
          this.$message.error(result.data.message)
        }
      }).catch(e => {
        this.loading.submit = false
      })
    }
  }
}
</script>

<style scoped>
</style>
