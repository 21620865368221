<template>
  <ics-dialog-inner :visible.sync="invoiceDialog.visible" :title="invoiceDialog.title" :show-submit="false" :show-cancel="!showButton" cancel-title="关闭" class="form-dialog" width="60%">
    <div class="form-area" style="width: 100%;height: 380px;overflow:auto;overflow-x:hidden;">
      <div class="form-inner">
        <el-form :model="invoiceDetail" label-width="120px">
          <el-row :gutter="80">
            <el-col :span="12">
              <el-form-item label="发票类型：">
                <p>{{ utils.statusFormat(invoiceDetail.invoiceType, 'invoiceType') }}</p>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="发票号码：">
                <p>{{ utils.isEffectiveCommon(invoiceDetail.invoiceNumber) }}</p>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="发票日期：">
                <p>{{ utils.isEffectiveCommon(invoiceDetail.billingDate) }}</p>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="发票代码：">
                <p>{{ utils.isEffectiveCommon(invoiceDetail.invoiceCode) }}</p>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="form-area">
            <el-row :gutter="80">
              <el-col :span="12">
                <div class="area-title">
                  <p class="title">
                    购买方
                  </p>
                </div>
                <div class="form-inner">
                  <el-row :gutter="80">
                    <el-col :span="24">
                      <el-form-item label="名称：">
                        <p>{{ utils.isEffectiveCommon(invoiceDetail.buyerTaxName) }}</p>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24">
                      <el-form-item label="纳税人识别号：">
                        <p>{{ utils.isEffectiveCommon(invoiceDetail.buyerTaxNumber) }}</p>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24">
                      <el-form-item label="地址、电话：">
                        <p>{{ utils.isEffectiveCommon(invoiceDetail.buyerAddressPhone) }}</p>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24">
                      <el-form-item label="开户行及账号：">
                        <p>{{ utils.isEffectiveCommon(invoiceDetail.buyerBankAccount) }}</p>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="area-title">
                  <p class="title">
                    销售方
                  </p>
                </div>
                <div class="form-inner">
                  <el-row :gutter="80">
                    <el-col :span="24">
                      <el-form-item label="名称：">
                        <p>{{ utils.isEffectiveCommon(invoiceDetail.sellerTaxName) }}</p>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24">
                      <el-form-item label="纳税人识别号：">
                        <p>{{ utils.isEffectiveCommon(invoiceDetail.sellerTaxNumber) }}</p>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24">
                      <el-form-item label="地址、电话：">
                        <p>{{ utils.isEffectiveCommon(invoiceDetail.sellerAddressPhone) }}</p>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24">
                      <el-form-item label="开户行及账号：">
                        <p>{{ utils.isEffectiveCommon(invoiceDetail.sellerBankAccount) }}</p>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </el-col>
            </el-row>
          </div>
          <div v-for="(item, index) in invoiceDetail.invoiceDetails" :key="index" class="form-area">
            <div class="area-title">
              <p class="title">
                货物或应税劳务、服务名称
              </p>
            </div>
            <div class="form-inner">
              <el-row :gutter="80">
                <el-col :span="12">
                  <el-form-item label="规格型号：">
                    <p>{{ utils.isEffectiveCommon(item.model) }}</p>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="单位：">
                    <p>{{ utils.isEffectiveCommon(item.units) }}</p>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="数量：">
                    <p>{{ utils.isEffectiveCommon(item.quantity) }}</p>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="单价：">
                    <p>{{ utils.isEffectiveCommon(item.unitPrice) }}</p>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="税率：">
                    <p>{{ utils.isEffectiveCommon(item.taxRate) }}</p>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="税额：">
                    <p>{{ utils.isEffectiveCommon(item.tax) }}</p>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </div>
          <div class="form-area">
            <div class="form-inner">
              <el-row :gutter="80">
                <el-col :span="12">
                  <el-form-item label="价税合计(大写）：">
                    <p>{{ utils.isEffectiveCommon(invoiceDetail.totalAmountStr) }}</p>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="价税合计(小写）：">
                    <p>{{ utils.isEffectiveCommon(invoiceDetail.totalAmount) }}</p>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="合计金额：">
                    <p>{{ utils.isEffectiveCommon(invoiceDetail.totalAmountTax) }}</p>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="合计税额：">
                    <p>{{ utils.isEffectiveCommon(invoiceDetail.totalTax) }}</p>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </div>
          <div class="form-area">
            <div class="form-inner">
              <el-row :gutter="80">
                <el-col :span="12">
                  <el-form-item label="收款人：">
                    <p>{{ utils.isEffectiveCommon(invoiceDetail.receiptName) }}</p>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="复核：">
                    <p>{{ utils.isEffectiveCommon(invoiceDetail.reviewerName) }}</p>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="开票人：">
                    <p>{{ utils.isEffectiveCommon(invoiceDetail.operatorName) }}</p>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-form>
      </div>
    </div>
    <template v-if="showButton" slot="btn-behind">
      <el-col :span="24" style="padding: 0px 0px 20px;margin:0 auto; text-align: center; margin-top: 20px;">
        <el-button class="add-btn" @click="close">
          信息有误，重新识别
        </el-button>
        <el-button class="add-btn" type="primary" @click="submit">
          确认无误
        </el-button>
      </el-col>
    </template>
  </ics-dialog-inner>
</template>

<script>
import IcsDialogInner from '@/components/dialog-inner'

export default {
  name: 'IcsInvoiceInner',
  components: { IcsDialogInner },
  props: {
    invoiceDialog: {
      type: Object,
      default () {
        return {}
      }
    },
    invoiceDetail: {
      type: Object,
      default () {
        return {}
      }
    },
    showButton: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    submit () {
      this.$emit('submit')
    }
  }
}
</script>

<style scoped>

</style>
