<template>
  <el-main>
    <page-title />
    <div class="partition-area">
      <ics-search-inner :search-form="searchForm" more-btn="高级搜索" :fold-search.sync="showFoldSearch" @submit-search="search" @clear-search="clearSearch">
        <template>
          <el-col :span="6">
            <el-form-item label="发票编号">
              <el-input v-model="searchForm.invoiceCode" placeholder="请输入要搜索的发票编号" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="状态">
              <el-select v-model="searchForm.invoiceStatus" placeholder="请选择要搜索的状态" filterable>
                <el-option label="全部" value="" />
                <el-option v-for="item in constants.invoiceStatus" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="发票类型">
              <el-select v-model="searchForm.invoiceKind" placeholder="请选择要搜索的发票类型" filterable>
                <el-option label="全部" value="" />
                <el-option v-for="item in constants.invoiceKindType" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col />
          <el-col v-show="showFoldSearch" :span="6">
            <el-form-item label="采购方">
              <el-input v-model="searchForm.buyerTaxName" placeholder="请输入要搜索的采购方" />
            </el-form-item>
          </el-col>
          <el-col v-show="showFoldSearch" :span="6">
            <el-form-item label="销售方">
              <el-input v-model="searchForm.sellerTaxName" placeholder="请输入要搜索的采购方" />
            </el-form-item>
          </el-col>
          <el-col v-show="showFoldSearch" :span="12">
            <el-form-item label="开票时间">
              <ics-search-date :date.sync="billingArr" />
            </el-form-item>
          </el-col>
        </template>
      </ics-search-inner>
    </div>
    <div class="partition-table">
      <ics-table-inner v-loading="loading.list" :column-option="columnOption" :table-data="tableList.list" :page-num="tableList.pageNum" :page-size="tableList.pageSize" :pages="tableList.pages" :hide-export-btn="true" @changePageNum="changePageNum">
        <template slot="btn-inner">
          <el-button type="primary" @click="uploadInvoice">
            新增发票
          </el-button>
          <el-button type="primary" @click="importUploadInvoice">
            导入
          </el-button>
        </template>
        <template slot="table-columns-before">
          <el-table-column label="序号" type="index" :index="indexMethod" />
        </template>
        <template slot="table-columns-after">
          <el-table-column fixed="right" label="操作" width="140">
            <template v-slot="scope">
              <el-link type="primary" class="text-btn" @click="utils.downloadP('invoice', scope.row.invoiceUrl)">
                下载
              </el-link>
              <el-link type="primary" class="text-btn" @click="dialogInvoice(scope.row.id)">
                查看
              </el-link>
            </template>
          </el-table-column>
        </template>
      </ics-table-inner>
    </div>
    <ics-upload-inner :upload-dialog="uploadDialog" @confirmClose="confirmClose" @close="uploadClose" />
    <ics-import-upload-inner :upload-dialog="importUploadDialog" @close="uploadClose" />
    <ics-invoice-inner :invoice-dialog="dialog" :invoice-detail="invoiceDetail" />
  </el-main>
</template>

<script>
import { basePageListMixin } from '@/assets/js/mixins'
import utils from '@/assets/js/utils'
import IcsUploadInner from './components/upload-inner'
import IcsInvoiceInner from './components/invoice-inner'
import IcsImportUploadInner from './components/import-upload-inner'
export default {
  components: { IcsUploadInner, IcsInvoiceInner, IcsImportUploadInner },
  mixins: [basePageListMixin],
  data () {
    return {
      showFoldSearch: false,
      baseSearchForm: {
        invoiceCode: '',
        buyerTaxName: '',
        sellerTaxName: '',
        invoiceStatus: '',
        billingDateStart: '',
        billingDateEnd: ''
      },
      dialog: {
        visible: false,
        title: ''
      },
      uploadDialog: {
        visible: false,
        title: ''
      },
      importUploadDialog: {
        visible: false,
        title: ''
      },
      invoiceDetail: {},
      columnOption: [
        { label: '发票编号', prop: 'invoiceCode', formatter: this.utils.isEffective, minWidth: 120 },
        { label: '发票类型', prop: 'invoiceKind', formatter: (row, col, cell) => this.utils.statusFormat(cell, 'invoiceKindType'), minWidth: 100 },
        { label: '采购方', prop: 'buyerTaxName', formatter: this.utils.isEffective, minWidth: 190 },
        { label: '销售方', prop: 'sellerTaxName', formatter: this.utils.isEffective, minWidth: 190 },
        { label: '开票时间', prop: 'billingDate', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '金额(元)', prop: 'totalAmountTax', formatter: this.utils.tableMoneyFormat, minWidth: 100 },
        { label: '税额(元)', prop: 'totalTax', formatter: this.utils.tableMoneyFormat, minWidth: 100 },
        { label: '状态', prop: 'invoiceStatus', formatter: (row, col, cell) => this.utils.statusFormat(cell, 'invoiceStatus'), minWidth: 100 },
      ]
    }
  },
  computed: {
    billingArr: utils.computedDate('billingDateStart', 'billingDateEnd')
  },
  methods: {
    getList () {
      this.loading.list = true
      this.api.assets.invoice.listPage(this.utils.filterTrimValue(this.searchForm)).then(result => {
        this.tableList = result.data.data
      }).finally(() => {
        this.loading.list = false
      })
    },
    dialogInvoice (val) {
      this.dialog.title = '发票详情'
      this.dialog.visible = true
      this.getDetail(val)
    },
    getDetail (id) {
      this.api.assets.invoice.invoiceDetail(id).then(result => {
        this.invoiceDetail = result.data.data || {}
      }).finally(() => {
      })
    },
    uploadInvoice () {
      this.uploadDialog.title = '添加发票'
      this.uploadDialog.visible = true
    },
    importUploadInvoice () {
      this.importUploadDialog.title = '导入发票'
      this.importUploadDialog.visible = true
    },
    uploadClose () {
      this.getList()
    },
    confirmClose () {
      this.uploadDialog.visible = false
      this.getList()
    }
  }
}
</script>

<style>

</style>
