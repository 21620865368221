<template>
  <ics-dialog-inner :loading="loading.submit" :visible.sync="uploadDialog.visible" :title="uploadDialog.title" submit-title="导入" cancel-title="关闭" class="form-dialog" width="50%" @submit="submit" @close="close">
    <ics-page-inner title="发票信息" :show-header="true">
      <el-form ref="uploadForm" :model="formInfo" :rules="rules" label-width="140px" label-position="left" :label-suffix="constants.labelSuffix">
        <el-row :gutter="80">
          <el-col :span="12">
            <el-form-item label="发票类型">
              <el-select v-model="formInfo.invoiceKind" placeholder="请选择发票类型" filterable>
                <el-option v-for="item in constants.invoiceKindType" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="upload-inner" style="width: 90%; top: 50%; left: 50%; margin-left: 40px;">
          <fero-upload
            ref="upload"
            :limit="limit"
            :action="uploadUrl"
            :headers="headers"
            name="files"
            :file-list="formInfo.fileList"
            drag
            accept=".xlsx,.xls,.csv,.zip"
            :auto-upload="false"
            :before-upload="beforeUploadFile"
            :on-success="onSuccessFile"
            :on-preview="previewFile"
            :on-error="onErrorFile"
            :on-change="onChange"
            :on-remove="onChange"
            :on-exceed="onExceed"
          >
            <i class="el-icon-upload" />
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
            <div class="el-upload__text">
              请上传xlsx xls csv 和对应的zip
            </div>
          </fero-upload>
        </div>
        <el-row :gutter="80">
          <el-col :span="14">
            <el-form-item label="提示">
              <p>点击下载发票导入模板《<a href="/template/发票模板.xlsx">发票导入模板</a>》</p>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </ics-page-inner>
  </ics-dialog-inner>
</template>

<script>
import IcsDialogInner from '@/components/dialog-inner'
import { routeEnterMixin } from '@/assets/js/mixins'
import utils from "../../../../assets/js/utils";
import cfca from "../../../../assets/js/cfca-prove";
class FormProp {
  fileList = []
}
export default {
  name: 'IcsImportUploadInner',
  components: { IcsDialogInner },
  mixins: [routeEnterMixin],
  props: {
    uploadDialog: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data () {
    return {
      baseUploadUrl: process.env.VUE_APP_REQUEST_BASE_URL + `/${process.env.VUE_APP_PREFIX}/b/p/eaccount/InvoiceAuthBatch`,
      uploadUrl: '',
      limit: 2,
      formInfo: {
        invoiceKind: '',
        fileList: []
      },
      headers: {
        Token: this.$store.state.token
      },
      fileList: [],
      loading: {
        submit: false
      },
      rules: {},
    }
  },
  methods: {
    beforeUploadFile(file) {
      const fileSuffix = file.name.substring(file.name.lastIndexOf('.') + 1)
      const whiteList = ['xlsx', '.csv', 'xls', 'zip']
      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message({
          showClose: true,
          message: '上传文件只能是 xlsx、xls、csv、zip格式',
          type: 'warning'
        })
        this.loading.submit = false
        return false
      }
      const isLt = file.size / 1024 / 1024 < 2
      if (!isLt) {
        this.$message.error('上传文件最大为2M')
        this.loading.submit = false
        return isLt
      }
    },
    onSuccessFile(res, file) {
      if (['200', '2000'].includes(res.data.data.code)) {
        this.$message.success(res.message || '导入成功')
        this.uploadDialog.visible = false
        this.$emit('onSuccess', res.data)
      } else {
        this.$message.error(res.message || '导入失败')
        this.formInfo.fileList = []
      }
      this.loading.submit = false
    },
    onErrorFile(err, file, fileList) {
      const msg = JSON.parse(err.message)
      if (msg.message.indexOf('code') >= 0) {
        const data = JSON.parse(msg.message)
        console.log(data)
        if (data.code === 60000) {
          if (utils.getExplorer() === 'ie') {
            cfca.dataHunter(data.employeeCode, msg.path)
          } else {
            this.$message.warning('当前浏览器为：'+ utils.getExplorer() + ',请使用IE浏览器进行数字证书身份认证')
          }
        }
      } else {
        this.$message.error(msg.message || '上传失败')
      }
      this.formInfo.fileList = []
      this.loading.submit = false
      if (err.status === 401) {
        this.$store.commit('removeEmpInfo')
        window.location.href = process.env.VUE_APP_CONSOLE_DOMAIN + '/login?redirecturl=' + encodeURIComponent(window.location.href)
      }
    },
    onChange(file, fileList) {
      this.formInfo.fileList = fileList
    },
    onExceed() {
      this.$message.error(`一次只能上传${this.limit}个文件`)
    },
    submit() {
      if (this._.isEmpty(this.formInfo.fileList)) {
        this.$message.warning('请上传附件')
        return false
      }
      if (!this.formInfo.invoiceKind) {
        this.$message.warning('请选择发票类型')
        return false
      }
      this.$refs.uploadForm.validate().then(() => {
        this.loading.submit = true
        const fileData = new FormData()  //  用FormData存放上传文件
        this.formInfo.fileList.map((file,index)=>{
            fileData.append('files', file.raw)
          }
        )
        this.api.assets.invoice.invoiceAuthBatch(this.formInfo.invoiceKind, fileData).then(res => {
          this.onSuccessFile(res)
        }).catch(e => {
          this.loading.submit = false
        })
      })
    },
    close() {
      this.$refs.upload.clearFiles()
      this.formInfo.invoiceKind = ''
      this.loading.submit = false
      this.$emit('close')
    },
    previewFile(file) {
      this.utils.downloadFile(file.url, file.name)
    },
  }
}
</script>

<style scoped>

</style>
